import React from 'react';

export default () => (
  <svg viewBox="0 0 1680 385" width="100%" style={{ fill: 'white', display: 'block' }}>
    <g>
      <path d="M206,0c11.1,2,22.4,3.3,33.3,6c75.2,18.6,132.2,82.4,142.8,159.4c14.1,102.4-52.5,196-154,216.3
      c-7.3,1.5-14.8,2.3-22.2,3.4c-34,0-68,0-102,0c0-64.5,0-129,0.1-193.4c0-4.3,0.4-8.7,1.1-12.9c6.6-43.3,46.1-76,89.4-74.3
      c45.7,1.9,82.4,37.2,85.3,82.2c4.3,66.8-63.7,113.7-124.6,85.9c-1.3-0.6-2.6-1.1-4.5-1.8c0,20.5-0.1,40.3,0.2,60.1
      c0,1.2,2.3,3,3.8,3.4c19.5,5.6,39.4,6.7,59.4,3.5c84.6-13.6,139.2-93.9,120.9-177.3c-16.9-77.2-94.1-127.7-171-112
      C94.4,62.5,45.8,121.9,45.6,193.1c-0.1,62.3,0,124.6,0,186.9c0,1.7,0.2,3.3,0.4,5c-15.3,0-30.7,0-46,0c0-69,0-138,0-207
      c0.6-3.9,1.3-7.8,1.8-11.8C12.4,88.5,69.1,24.9,145.2,5.9C156,3.2,167.1,1.9,178,0C187.3,0,196.7,0,206,0z"
      />
      <path d="M1680,272c-10.3,0-20.6,0-31.7,0c0-7.7,0-15.3,0-22.9c-0.1-29.5,0-59-0.3-88.5c-0.3-23.1-17.7-43.9-40.3-48.6
      c-31-6.5-62.5,18.6-62.6,50.4c-0.1,34.5,0.1,69,0.2,103.5c0,2,0,3.9,0,6.2c-10.5,0-20.4,0-30.8,0c0-60.9,0-121.8,0-183.2
      c9.9,0,19.9,0,30.5,0c0,3,0,6.1,0,10.1c10-8.9,20.6-14.4,32.6-17.4c47.2-11.9,96.6,22.8,101.4,71.2c0.1,1.4,0.6,2.9,0.9,4.3
      C1680,195.3,1680,233.7,1680,272z"
      />
      <path d="M481.8,251.5c0,30.4,0,60.7,0,91.4c-10.5,0-20.4,0-30.6,0c-0.1-1.3-0.3-2.6-0.3-3.9
      c0-53.2-0.2-106.3,0.1-159.5c0.3-48.1,34-88.7,81-98.2c56.5-11.4,111.8,28.3,119.2,85.5c7.3,56.4-31.5,107-88.1,113.8
      c-29.7,3.5-55.3-6.7-77.3-26.5c-1-0.9-1.5-2.5-2.3-3.8C482.9,250.7,482.4,251.1,481.8,251.5z M551.7,250.4
      c38.7-0.1,69.6-31.3,69.6-70.1c-0.1-38.7-31.4-70-69.9-69.8c-38.6,0.2-69.6,31.4-69.5,70.2C481.8,219.3,513,250.5,551.7,250.4z"
      />
      <path d="M1127.7,221.3c14.8,22.6,46.1,33.8,73.8,26.4c16.9-4.5,30.4-14.2,40.1-28.7c9.7-14.5,13.3-30.7,11.2-48.4
      c10.5,0,20.6,0,30.6,0c5.5,38.7-17.6,89.2-69.4,105.7c-49,15.6-101.9-8.4-122.6-55.5c-20.8-47.3-2.5-102.8,42.6-128.5
      c46.7-26.6,99-9.2,123.4,19.9C1214.2,148.5,1171.1,184.8,1127.7,221.3z M1114.7,191.8c31.1-26.1,61.3-51.5,91.8-77.2
      c-23.3-9.7-53-3.2-71.9,15.7C1118,147,1111.7,167.3,1114.7,191.8z"
      />
      <path d="M864,272.1c-10.5,0-20.5,0-30.9,0c0-6.2,0-12.1,0-18.9c-1.6,1.3-2.5,2-3.3,2.7c-57.3,49.6-145.6,22-164.2-51.3
      c-17.1-67.6,38.5-131.9,107.8-124.9c51,5.2,90,47.7,90.5,98.9c0.3,30,0.1,60,0.1,90C864,269.6,864,270.6,864,272.1z M693.5,180
      c-0.1,38.4,31,69.9,69.2,70.1c38.6,0.1,70.3-30.8,70.4-68.8c0.1-39.7-30.7-71.1-69.6-71.1C724.9,110,693.6,141.3,693.5,180z"
      />
      <path d="M1295.3,180c0.1-55.6,45.3-100.8,100.7-100.8c55.6,0,101,45.7,100.7,101.3c-0.3,55.7-45.4,100.6-101,100.5
      C1340.3,280.9,1295.2,235.6,1295.3,180z M1395.9,250c38.6,0,70-31.2,70-69.8c0.1-38.5-31.3-70.1-69.7-70.2
      c-38.5-0.1-70,31.3-70.1,69.8C1326.1,218.5,1357.4,250,1395.9,250z"
      />
      <path d="M873.8,88.3c6.1,0,11.5,0,17.7,0c0-23.7,0-47.1,0-70.8c10.6,0,20.5,0,31,0c0,23.5,0,46.9,0,70.8
      c15.7,0,30.8,0,46.2,0c0,10.4,0,20.3,0,30.9c-15.2,0-30.4,0-46.1,0c0,51.3,0,101.9,0,152.8c-10.4,0-20.3,0-30.8,0c0-2.2,0-4.1,0-6
      c0-47-0.1-94,0.1-141c0-4.5-1.1-6.3-5.8-5.8c-3.9,0.4-7.9,0.1-12.2,0.1C873.8,108.9,873.8,99,873.8,88.3z"
      />
      <path d="M1015.5,115.1c14.7-13.2,31.5-22.3,50.9-25.7c6.8-1.2,13.8-1.3,21.2-2c0,10.2,0,19.3,0,28.4
      c0,3.5-2.6,2.9-4.6,2.9c-15.4,0.2-29.2,5.1-41.1,14.6c-17.3,13.7-26.7,31.7-27,53.9c-0.3,26.3-0.1,52.7-0.1,79c0,1.8,0,3.6,0,5.8
      c-10.3,0-20.2,0-30.5,0c0-61.1,0-122.1,0-183.3c9.7,0,19.5,0,29.9,0c0,8.6,0,17.2,0,25.8C1014.7,114.7,1015.1,114.9,1015.5,115.1z"
      />
    </g>
  </svg>
);
