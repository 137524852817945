import React from 'react';
import { timeFormat } from 'd3-time-format';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import { MDXProvider } from '@mdx-js/react';
import PlatformButton from '../PlatformButton';
import Host from '../Host';
import PatreonIcon from '../Icons/Patreon';
import styles from './ShowHeader.module.css';

const localeMap = {
  en: 'English',
  zh: '中文',
};

const components = {
  Host: () => null,
  SectionTitle: () => null,
  Body: () => null,
  Content: () => null,
  Description: ({ children }) => children,
};

export default ({
  episode,
  data: {
    title,
    rssData: { description },
    headerColor,
    headerTextColor,
    headerAccentColor,
    showImage,
    showLang,
    body,
    locale,
    patreonURL,
    patreonText,
    patreonImg,
    page,
    ...restProps
  },
}) => (
  <div
    className={styles.showHeader}
    style={{
      '--header-color': headerColor,
      '--header-textColor': headerTextColor,
      '--header-accentColor': headerAccentColor,
    }}
  >
    <div className={styles.innerWrap}>
      <a href={`/shows/${page}`}>
        <img className={styles.showImage} src={showImage} alt={title} />
      </a>

      <div className={styles.content}>
        {episode ? (
          <>
            <a className={styles.showLink} href={`/shows/${page}`}>
              <h3 className={styles.showTitle}>{title}</h3>
            </a>
            <div>{timeFormat('%Y-%m-%d')(new Date(episode.pubDate))}</div>
            <h1 className={styles.title}>{(episode && episode.title) || title}</h1>
          </>
        ) : (
          <a className={styles.showLink} href={`/shows/${page}`}>
            <h1 className={styles.title}>{(episode && episode.title) || title}</h1>
            <div className={styles.localeLabel}>{showLang || localeMap[locale]}</div>
          </a>
        )}

        {!episode && (
          <div className={styles.description}>
            <div className={styles.customDescription}>
              <MDXProvider components={components}>
                <MDXRenderer>
                  {body}
                </MDXRenderer>
              </MDXProvider>
            </div>
            <div
              className={styles.descriptionFallback}
              dangerouslySetInnerHTML={{ __html: description }}
            />
          </div>
        )}

        {episode && (
          <div className={styles.episodesHostsBox}>
            <MDXProvider components={{
              Host,
              Description: () => null,
              Content: () => null,
              SectionTitle: () => null,
            }}
            >
              <MDXRenderer>
                {body}
              </MDXRenderer>
            </MDXProvider>
          </div>
        )}

        <div className={styles.platformButtons}>
          {Object.keys(restProps)
            .filter((d) => d.indexOf('platform') === 0 && restProps[d])
            .map((d) => (
              <PlatformButton
                key={d}
                platform={d.replace('platform', '')}
                platformURL={restProps[d]}
              />
            ))}
        </div>
      </div>
    </div>
    {patreonText && patreonURL
      && (
      <a
        className={styles.patreonLink}
        href={patreonURL}
        target="_blank"
        rel="noopener noreferrer"
      >
        <div className={styles.patreonText}>{patreonText}</div>
        <div className={styles.patreonIconWrapper}>
          {patreonImg ? (<img src={patreonImg} alt="" />) : (<PatreonIcon />)}
        </div>
      </a>
      )}
  </div>
);
