import React, { useState, useEffect } from 'react';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import { MDXProvider } from '@mdx-js/react';
import * as RSSParser from 'rss-parser';
import { i18n } from '../../utils/i18n';
import Layout from '../../components/Layout';
import Host from '../../components/Host/HostWide';
import SectionTitle from '../../components/SectionTitle';
import ShowHeader from '../../components/ShowHeader';
import Episode from '../../components/Episode';
import ShowEntry from '../../components/ShowEntry';
import Recirc from '../../components/Recirc';
import InlinePagination from '../../components/InlinePagination';
import styles from './Show.module.css';

// Components we are passing in as shortcode options to mdx files
const components = {
  // These get passed through to fixed components
  Host,
  SectionTitle,
  Description: () => null,
  Content: () => null,
};

// Read the embed url from the rss item
const getEpisodePlayer = (item) => {
  try {
    return item.media['media:player'][0].$.url;
  } catch (_) {
    return item.link;
  }
};

// Our most reliable source of the episode slug is from the player
// url go figure so we read that
const getEpisodeSlug = (item, show) => {
  const playerURL = getEpisodePlayer(item);
  const playerURLSegments = playerURL.split('/').map((s) => s.toLowerCase());
  if (playerURLSegments.indexOf(show.toLowerCase())) {
    return playerURLSegments[playerURLSegments.indexOf(show.toLowerCase()) + 1];
  }
  return playerURLSegments[playerURLSegments.length - 2];
};

const getEpisodeItem = (items, episode, show) => items.find(
  (item) => getEpisodeSlug(item, show) === episode,
);

export default ({ pageContext, params, location }) => {
  const episode = params['*'];

  // How many episodes are visible
  const qp = new URLSearchParams(location.search);
  const initialVisible = qp.get('expanded') ? -1 : 8;

  // The data coming from the RSS feed
  const [data, setData] = useState({ items: [] });

  // Fetch episodes on mount, even though it is the same
  useEffect(() => {
    const fetchData = async () => {
      const parser = new RSSParser({
        customFields: { item: [['media:content', 'media']] },
      });
      const result = await parser.parseURL(pageContext.rss);

      setData(result);
    };

    fetchData();
  }, []);

  return (
    <Layout pageContext={pageContext}>
      {pageContext.rssData && (
        <ShowHeader
          data={pageContext}
          episode={getEpisodeItem(data.items, episode, pageContext.page)}
        />
      )}

      {episode
        // This block is used when we are on an episode page
        ? (data.items.length > 0 && (
          <Episode
            item={getEpisodeItem(data.items, episode, pageContext.page)}
            show={pageContext.page}
            slug={getEpisodeSlug(getEpisodeItem(
              data.items, episode, pageContext.page,
            ), pageContext.page)}
            player={getEpisodePlayer(getEpisodeItem(
              data.items, episode, pageContext.page,
            ))}
            body={pageContext.body}
            locale={pageContext.locale}
            items={data.items}
          />
        ))
        // This block is used when we are on a show page
        : (
          <>
            <div>
              <div className={styles.latestEpisodesTitle}>
                <SectionTitle size="h3">{i18n(pageContext.locale, 'showLatestEpisode')}</SectionTitle>
              </div>

              <iframe
                className={styles.iframe}
                width="100%"
                src={pageContext.player}
                title={pageContext.title}
                frameBorder="0"
              />
              <div className={styles.latestEpisodes}>
                <InlinePagination
                  initial={initialVisible}
                  buttonText={i18n(pageContext.locale, 'showShowMoreEpisodes')}
                >
                  {data.items.map((item) => (
                    <ShowEntry
                      key={item.title}
                      locale={pageContext.locale}
                      show={pageContext.page}
                      slug={getEpisodeSlug(item, pageContext.page)}
                      item={item}
                    />
                  ))}
                </InlinePagination>
              </div>

              <div className={styles.host}>
                <MDXProvider components={components}>
                  <MDXRenderer>
                    {pageContext.body}
                  </MDXRenderer>
                </MDXProvider>
              </div>
            </div>

            <MDXProvider components={{
              SectionTitle: () => null,
              Description: () => null,
              Host: () => null,
            }}
            >
              <MDXRenderer>
                {pageContext.body}
              </MDXRenderer>
            </MDXProvider>

            <Recirc locale={pageContext.locale} currentShow={pageContext.page} />
          </>
        )}
    </Layout>
  );
};
