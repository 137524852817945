import React, { useRef } from 'react';
import cx from 'classnames';
import { StaticQuery, graphql } from 'gatsby';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import { MDXProvider } from '@mdx-js/react';
import dateformat from 'dateformat';
import Arrow from '../Icons/Arrow';
import SectionTitle from '../SectionTitle';
import HighlightTitle from '../HighlightTitle';
import { i18n } from '../../utils/i18n';
import styles from './Episode.module.css';

// Read the embed url from the rss item
const getEpisodePlayer = (item) => {
  try {
    return item.media['media:player'][0].$.url;
  } catch (_) {
    return item.link;
  }
};

// Our most reliable source of the episode slug is from the player
// url go figure so we read that
const getEpisodeSlug = (item, show) => {
  const playerURL = getEpisodePlayer(item);
  const playerURLSegments = playerURL.split('/');
  return playerURLSegments[playerURLSegments.indexOf(show) + 1];
};

class Episode extends React.Component {
  constructor() {
    super();
    this.state = {
      showTranscript: false,
    };
  }

  render() {
    const {
      item, player, show, slug, locale, items,
    } = this.props;
    const { showTranscript } = this.state;
    return (
      <StaticQuery
        query={graphql`
      {
        episodes: allFile(filter: {
          sourceInstanceName: {eq: "episodes"}
        }) {
          edges {
            node {
              relativeDirectory
              childMdx {
                body
                fields {
                  page
                }
              }
            }
          }
        }
      }
    `}
        render={({ episodes: { edges } }) => {
          const mdx = edges.find(({ node }) => node.relativeDirectory === show
            && node.childMdx
            && node.childMdx.fields.page === slug);
          const transcriptRef = useRef();
          const hasTranscript = transcriptRef
            && transcriptRef.current
            && transcriptRef.current.innerText;
          return (
            <>
              <div className={styles.episodeWrap}>
                <div className={styles.contentWrap}>
                  <iframe
                    className={styles.player}
                    title={item.title}
                    src={player}
                    frameBorder="0"
                  />

                  <SectionTitle>{i18n(locale, 'aboutThisEpisode')}</SectionTitle>

                  {mdx && (
                  <div className={styles.content}>
                    <MDXProvider components={{ Transcript: () => null }}>
                      <MDXRenderer>
                        {mdx.node.childMdx.body}
                      </MDXRenderer>
                    </MDXProvider>
                  </div>
                  )}
                  <div
                    className={styles.content}
                    dangerouslySetInnerHTML={{ __html: item['content:encoded'] }}
                  />
                  <hr className={styles.divider} />
                  {mdx && (
                  <div className={hasTranscript || styles.hidden}>
                    <button
                      className={styles.transcriptToggle}
                      type="button"
                      onClick={() => {
                        this.setState({
                          showTranscript: !showTranscript,
                        });
                      }}
                    >
                      <SectionTitle>
                        {showTranscript ? '▾ ' : '▸ '}
                        {i18n(locale, 'transcript')}
                      </SectionTitle>
                    </button>

                    <div
                      ref={transcriptRef}
                      className={cx(styles.transcriptBox, showTranscript || styles.hidden)}
                    >
                      <MDXProvider components={{ Description: () => null }}>
                        <MDXRenderer>
                          {mdx.node.childMdx.body}
                        </MDXRenderer>
                      </MDXProvider>
                    </div>
                    <hr className={styles.divider} />
                  </div>
                  )}
                  <a className={styles.backToShowPage} href={`/shows/${show}`}>
                    {i18n(locale, 'backToShowPage')}
                  </a>
                </div>

                <div className={styles.moreEpisodes}>
                  <HighlightTitle title={i18n(locale, 'newestEpisodes')} />
                  <div className={styles.episodes}>
                    {items
                      .filter(
                        (episode) => getEpisodeSlug(item, show) !== getEpisodeSlug(episode, show),
                      )
                      .slice(0, 3).map((episode) => (
                        <div key={episode.title}>
                          <a
                            className={styles.episode}
                            href={getEpisodeSlug(episode, show)}
                          >
                            <div className={styles.episodeDate}>
                              {dateformat(new Date(episode.pubDate), 'm/d/yyyy')}
                            </div>
                            <h4>{episode.title}</h4>
                          </a>
                        </div>
                      ))}
                  </div>
                  <a className={styles.allEpisodesLink} href={`/${locale}/shows/${show}/?expanded=1`}>
                    {i18n(locale, 'seeAllEpisodes')}
                    <div className={styles.arrowWrap}>
                      <Arrow direction="right" />
                    </div>
                  </a>
                </div>
              </div>
            </>
          );
        }}
      />
    );
  }
}

export default Episode;
