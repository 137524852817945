import React from 'react';
import { Link } from 'gatsby';
import styles from './ShowEntry.module.css';

export default ({
  locale, show, slug, item,
}) => (
  <div className={styles.showEntry} key={slug}>
    <Link to={`/${locale}/shows/${show}/${slug}`}>
      {item.title}
    </Link>
  </div>
);
