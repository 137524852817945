import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import Grid from '../Grid';
import GridCard from '../Grid/GridCard';
import { i18n } from '../../utils/i18n';
import styles from './Recirc.module.css';

export default ({ locale, currentShow }) => (
  <StaticQuery
    query={graphql`
      {
        podcasts: allFile(
          filter: {
            sourceInstanceName: {eq: "shows"}
          }
        ) {
          edges {
            node {
              childMdx {
                fields {
                  page
                  locale
                }
                frontmatter {
                  title
                  showImage
                }
              }
            }
          }
        }
      }
    `}
    render={({ podcasts: { edges } }) => {
      const podcasts = edges.sort(() => Math.random() - 0.5)
        .filter(
          ({ node }) => node.childMdx.fields.locale === locale
          && node.childMdx.fields.page !== currentShow,
        ).map(
          ({ node }) => ({
            ...node.childMdx.fields,
            ...node.childMdx.frontmatter,
          }),
        );

      if (podcasts.length === 0) {
        return null;
      }

      return (
        <Grid
          className={styles.recircWrapper}
          title={i18n(locale, 'showRecircTitle')}
          columns={5}
          titleSize="h3"
        >
          {podcasts.slice(0, 5).map((podcast) => (
            <GridCard
              key={`${podcast.title}-${podcast.showImage}-${Math.random()}`}
              image={podcast.showImage}
              href={`/shows/${podcast.page}`}
              title={podcast.title}
              titleSize="h6"
            />
          ))}
        </Grid>
      );
    }}
  />
);
