import React from 'react';
import cx from 'classnames';
import SectionTitle from '../SectionTitle';
import styles from './Host.module.css';

export default ({
  name, title, children, photo,
}) => (
  <div className={styles.hostBoxWide}>
    {photo && (
      <div className={cx(styles.photo, styles.photoRight)}>
        <img src={photo} alt={name} />
        <h3 className={styles.photoTitle}>{`${title} - ${name}`}</h3>
      </div>
    )}

    <div className={styles.profile}>
      <div className={styles.profileTitle}><SectionTitle size="h3">{`${title} - ${name}`}</SectionTitle></div>
      <div className={styles.bio}>{children}</div>
    </div>
  </div>
);
