import React from 'react';
import cx from 'classnames';
import styles from './Host.module.css';

export default ({ name, title, photo }) => (
  <div className={styles.hostBox}>
    <div className={cx(styles.photo, styles.photoSmall)}>
      <img src={photo} alt={name} />
    </div>
    <h6 className={styles.title}>{`${title} - ${name}`}</h6>
  </div>
);
